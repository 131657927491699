import { ExperimentsConsts } from '../../../consts/experiments';
import { ContactDetails } from '../../../types/ambassador/bookings/ambassador-bookings-v2-booking';
import { FormApiContext } from '../utils';

export type MapContactDetailsArgs = {
  contactDetails: ContactDetails;
};

export const mapContactDetails = ({
  contactDetails,
  experiments,
}: MapContactDetailsArgs &
  Pick<FormApiContext, 'experiments'>): ContactDetails => {
  const isSplitJapaneseNamesOnFormEnabled = experiments.enabled(
    ExperimentsConsts.SplitJapaneseNamesOnForm,
  );

  const isSendFirstNameAsEmailWhenFIrstNameIsEmpty = experiments.enabled(
    ExperimentsConsts.SendFirstNameAsEmailWhenFIrstNameIsEmpty,
  );

  if (!isPhoneValid(contactDetails.phone!)) {
    delete contactDetails.phone;
  }

  if (contactDetails.lastName) {
    return contactDetails;
  }

  let firstName = contactDetails.firstName?.trim();
  let lastName: string | undefined = '';

  if (isSendFirstNameAsEmailWhenFIrstNameIsEmpty && !firstName) {
    firstName = contactDetails.email!;
    contactDetails.firstName = firstName;
  } else {
    const nameParts = contactDetails?.firstName
      ?.trim()
      .split(isSplitJapaneseNamesOnFormEnabled ? /\s/ : ' ');
    firstName = nameParts?.slice(0, -1).join(' ').trim();

    lastName =
      (nameParts?.length || 0) > 1
        ? nameParts?.[nameParts.length - 1]
        : undefined;
  }

  return {
    ...contactDetails,
    ...(firstName && ({ firstName } as { firstName: string | undefined })),
    ...(lastName && ({ lastName } as { lastName: string | undefined })),
  };
};

const isPhoneValid = (phone: string): boolean => {
  if (!phone) {
    return false;
  }
  const phoneValidatorRegEx = /^[\d\s+\-().]+$/;
  return (phone.match(phoneValidatorRegEx)?.length || 0) > 0;
};
